import styled from 'styled-components';

export const CreditElement = styled.div`
  transition: all 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1.0 : 0.0)};
  opacity: ${({ hasBeenVisible }) => (hasBeenVisible ? 1.0 : 0.0)};
  transform: translateY(${({ isVisible }) => (isVisible ? ' 0%' : ' 2%')});
  transform: translateY(
    ${({ hasBeenVisible }) => (hasBeenVisible ? ' 0%' : ' 2%')}
  );
  display: flex;
  width: 100%;
  flex-flow: ${({ alignment }) =>
    alignment == 'left' ? 'row' : 'row-reverse'};
`;
export const StyledCredit = styled.span`
  display: inline-block;
  font-family: 'IBM Plex Mono', Menlo, monospace;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.fadedText};
  transform: translateY(-4px);
`;

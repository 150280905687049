import React from 'react';
import PropTypes from 'prop-types';
import { Element, Context } from './subheader.css';

const Subheader = ({ context, headline }) => {
  return (
    <Element>
      <Context>{context}</Context>
      <h5>{headline}</h5>
    </Element>
  );
};

Subheader.propTypes = {
  context: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default Subheader;

import styled from 'styled-components';

export const Element = styled.div``;

export const Context = styled.h6`
  margin-bottom: -0.2rem;
  color: ${({ theme }) => theme.fadedText};
  font-family: 'IBM Plex Mono', Menlo, monospace;
  font-size: 1.1rem;
  font-weight: 300;
`;

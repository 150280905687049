import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { CreditElement, StyledCredit } from './credit.css';

const Credit = ({ source, url, alignment = 'left' }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible, hasBeenVisible }) => (
        <CreditElement
          hasBeenVisible={hasBeenVisible}
          isVisible={isVisible}
          alignment={alignment}
        >
          <StyledCredit>
            Credit: <a href={url}>{source}</a>
          </StyledCredit>
        </CreditElement>
      )}
    </IO>
  );
};

Credit.propTypes = {
  source: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['left', 'right']),
};

export default Credit;
